@tailwind base;
@tailwind components;
@tailwind utilities;

/* Font: Poppins */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Font: Fira Code */
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@layer base {
	:root {
		--color-background: 15 0 51;
		--color-darker-background: 9 0 24;
		--color-primary: 85 0 218;
		--color-secundary: 166 109 255;

		/* Skills Section */
		--skill-element-square-size: 100px;
		--skill-element-horizontal-width: 120px;
		--skill-element-horizontal-height: 70px;
		--skill-elements-count: 12;
	}

	body {
		font-family: 'Poppins', 'Courier New', Courier, monospace;
		color: #FFFFFF;
	}
}